import { RoomEvent } from "livekit-client";
import type { Room, DataPacket_Kind, RemoteParticipant } from "livekit-client";

import { logger } from "./logger";
import type { Transport } from "./types";

type CloseTransport = () => void;

export enum MessageType {
  EXPLICIT = 1,
  BROADCAST = 2,
}

export interface SendExtras {
  topic?: string;
  extraDst?: Array<string>;
}

export interface RecvExtras {
  type: MessageType;
}

type MessageListener = (src: string, payload: Uint8Array, extras: RecvExtras) => void;

const IDENTITY_ID_REGEX = new RegExp(/^(?:\w+)-(?:\w+)-(?<id>.+)$/);

// shim around LiveKit room to implement the Transport interface required by the skybus tunnel. does
// not manage the lifecycle of the room; simply uses it to send and receive messages. this class
// should be kept as simple as possible, with additional listeners being bound at the React layer
// for more complex business logic.
export class LiveKitTransportShim implements Transport<SendExtras, RecvExtras> {
  private _id: string;
  private _room: Room;
  private _messageListenerKey: number;
  private _messageListeners: Map<number, MessageListener>;

  // maps of LiveKit SID <-> Skydio ID (user uuid, vehicle/dock ID, etc.)
  private _sidToId: Map<string, string>;
  private _idToSid: Map<string, string>;

  constructor(room: Room) {
    console.debug("@skydio/skybus-experimental: constructing transport");
    this._id = Math.round(Math.random() * 10e6).toString();
    this._room = room;
    this._messageListenerKey = 0;
    this._messageListeners = new Map();
    this._sidToId = new Map();
    this._idToSid = new Map();
  }

  id(): string {
    return this._id;
  }

  start(): CloseTransport {
    const updateKnownParticipants = this._updateKnownParticipants.bind(this);
    const disconnectedListener = this._disconnectedListener.bind(this);
    const dataReceivedListener = this._dataReceivedListener.bind(this);
    const participantConnectedListener = this._participantConnectedListener.bind(this);
    const participantDisconnectedListener = this._participantDisconnectedListener.bind(this);

    // update known partcipants based on room state on start
    this._updateKnownParticipants();

    // add room listeners
    this._room
      .addListener(RoomEvent.Connected, updateKnownParticipants)
      .addListener(RoomEvent.Reconnected, updateKnownParticipants)
      .addListener(RoomEvent.Disconnected, disconnectedListener)
      .addListener(RoomEvent.DataReceived, dataReceivedListener)
      .addListener(RoomEvent.ParticipantConnected, participantConnectedListener)
      .addListener(RoomEvent.ParticipantDisconnected, participantDisconnectedListener);

    // clear message and room listeners
    return () => {
      this._messageListeners.clear();
      this._sidToId.clear();
      this._idToSid.clear();
      this._room
        .removeListener(RoomEvent.Connected, updateKnownParticipants)
        .removeListener(RoomEvent.Reconnected, updateKnownParticipants)
        .removeListener(RoomEvent.Disconnected, disconnectedListener)
        .removeListener(RoomEvent.DataReceived, dataReceivedListener)
        .removeListener(RoomEvent.ParticipantConnected, participantConnectedListener)
        .removeListener(RoomEvent.ParticipantDisconnected, participantDisconnectedListener);
    };
  }

  send(dst: string, payload: Uint8Array, extras?: SendExtras): void {
    const sid = this._idToSid.get(dst);
    if (sid == null) {
      logger.warn("attempted to send message to unknown participant", {
        id: this._id,
        dst,
        known: this._idToSid,
      });
      return;
    }

    const destination = [sid];
    if (extras?.extraDst != null) {
      destination.push(...extras.extraDst);
    }

    this._room.localParticipant.publishData(payload, {
      destinationSids: destination,
      topic: extras?.topic,
      reliable: false,
    });
  }

  addMessageListener(messageListener: MessageListener): () => void {
    const key = this._nextMessageListenerKey();
    this._messageListeners.set(key, messageListener);
    return () => this._messageListeners.delete(key);
  }

  private _disconnectedListener() {
    this._sidToId.clear();
    this._idToSid.clear();
  }

  private _updateKnownParticipants() {
    for (const participant of this._room.remoteParticipants.values()) {
      this._participantConnectedListener(participant);
    }
  }

  private _dataReceivedListener(
    payload: Uint8Array,
    participant?: RemoteParticipant,
    _kind?: DataPacket_Kind,
    _topic?: string,
    destinationSids: Array<string> = []
  ) {
    if (participant == null) {
      logger.warn("received message without sender");
      return;
    }

    const type = destinationSids.length > 0 ? MessageType.EXPLICIT : MessageType.BROADCAST;
    for (const listener of this._messageListeners.values()) {
      const id = this._sidToId.get(participant.sid);
      if (id == null) {
        logger.warn("received message from unknown participant", {
          sid: participant.sid,
        });
      } else {
        listener(id, payload, { type });
      }
    }
  }

  private _participantConnectedListener({ sid, identity }: RemoteParticipant) {
    const match = identity.match(IDENTITY_ID_REGEX);
    if (match?.groups?.id == null) {
      logger.warn("participant with invalid identity connected", { identity, match });
    } else {
      this._sidToId.set(sid, match.groups.id);
      this._idToSid.set(match.groups.id, sid);
    }
  }

  private _participantDisconnectedListener({ sid, identity }: RemoteParticipant) {
    const match = identity.match(IDENTITY_ID_REGEX);
    if (match?.groups?.id == null) {
      logger.warn("participant with invalid identity disconnected", { identity, match });
    } else {
      this._sidToId.delete(sid);
      this._idToSid.delete(match.groups.id);
    }
  }

  private _nextMessageListenerKey(): number {
    return ++this._messageListenerKey;
  }
}
