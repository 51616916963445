// source: pbtypes/vehicle/infrastructure/flight_deck/server.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_any_pb = require('google-protobuf/google/protobuf/any_pb.js');
goog.object.extend(proto, google_protobuf_any_pb);
goog.exportSymbol('proto.flight_deck.ApiResponse', null, global);
goog.exportSymbol('proto.flight_deck.Error', null, global);
goog.exportSymbol('proto.flight_deck.ServerInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.ApiResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.ApiResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.ApiResponse.displayName = 'proto.flight_deck.ApiResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.ServerInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.ServerInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.ServerInfo.displayName = 'proto.flight_deck.ServerInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flight_deck.Error = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flight_deck.Error, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flight_deck.Error.displayName = 'proto.flight_deck.Error';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.ApiResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.ApiResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.ApiResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.ApiResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
server: (f = msg.getServer()) && proto.flight_deck.ServerInfo.toObject(includeInstance, f),
error: (f = msg.getError()) && proto.flight_deck.Error.toObject(includeInstance, f),
data: (f = msg.getData()) && google_protobuf_any_pb.Any.toObject(includeInstance, f),
extraData: (f = msg.getExtraData()) && google_protobuf_any_pb.Any.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.ApiResponse}
 */
proto.flight_deck.ApiResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.ApiResponse;
  return proto.flight_deck.ApiResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.ApiResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.ApiResponse}
 */
proto.flight_deck.ApiResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.flight_deck.ServerInfo;
      reader.readMessage(value,proto.flight_deck.ServerInfo.deserializeBinaryFromReader);
      msg.setServer(value);
      break;
    case 2:
      var value = new proto.flight_deck.Error;
      reader.readMessage(value,proto.flight_deck.Error.deserializeBinaryFromReader);
      msg.setError(value);
      break;
    case 3:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 4:
      var value = new google_protobuf_any_pb.Any;
      reader.readMessage(value,google_protobuf_any_pb.Any.deserializeBinaryFromReader);
      msg.setExtraData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.ApiResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.ApiResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.ApiResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.ApiResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServer();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.flight_deck.ServerInfo.serializeBinaryToWriter
    );
  }
  f = message.getError();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.flight_deck.Error.serializeBinaryToWriter
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
  f = message.getExtraData();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_any_pb.Any.serializeBinaryToWriter
    );
  }
};


/**
 * optional ServerInfo server = 1;
 * @return {?proto.flight_deck.ServerInfo}
 */
proto.flight_deck.ApiResponse.prototype.getServer = function() {
  return /** @type{?proto.flight_deck.ServerInfo} */ (
    jspb.Message.getWrapperField(this, proto.flight_deck.ServerInfo, 1));
};


/**
 * @param {?proto.flight_deck.ServerInfo|undefined} value
 * @return {!proto.flight_deck.ApiResponse} returns this
*/
proto.flight_deck.ApiResponse.prototype.setServer = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.ApiResponse} returns this
 */
proto.flight_deck.ApiResponse.prototype.clearServer = function() {
  return this.setServer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.ApiResponse.prototype.hasServer = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Error error = 2;
 * @return {?proto.flight_deck.Error}
 */
proto.flight_deck.ApiResponse.prototype.getError = function() {
  return /** @type{?proto.flight_deck.Error} */ (
    jspb.Message.getWrapperField(this, proto.flight_deck.Error, 2));
};


/**
 * @param {?proto.flight_deck.Error|undefined} value
 * @return {!proto.flight_deck.ApiResponse} returns this
*/
proto.flight_deck.ApiResponse.prototype.setError = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.ApiResponse} returns this
 */
proto.flight_deck.ApiResponse.prototype.clearError = function() {
  return this.setError(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.ApiResponse.prototype.hasError = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Any data = 3;
 * @return {?proto.google.protobuf.Any}
 */
proto.flight_deck.ApiResponse.prototype.getData = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 3));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.flight_deck.ApiResponse} returns this
*/
proto.flight_deck.ApiResponse.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.ApiResponse} returns this
 */
proto.flight_deck.ApiResponse.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.ApiResponse.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Any extra_data = 4;
 * @return {?proto.google.protobuf.Any}
 */
proto.flight_deck.ApiResponse.prototype.getExtraData = function() {
  return /** @type{?proto.google.protobuf.Any} */ (
    jspb.Message.getWrapperField(this, google_protobuf_any_pb.Any, 4));
};


/**
 * @param {?proto.google.protobuf.Any|undefined} value
 * @return {!proto.flight_deck.ApiResponse} returns this
*/
proto.flight_deck.ApiResponse.prototype.setExtraData = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flight_deck.ApiResponse} returns this
 */
proto.flight_deck.ApiResponse.prototype.clearExtraData = function() {
  return this.setExtraData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flight_deck.ApiResponse.prototype.hasExtraData = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.ServerInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.ServerInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.ServerInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.ServerInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
apiVersionMajor: jspb.Message.getFieldWithDefault(msg, 1, 0),
apiVersionMinor: jspb.Message.getFieldWithDefault(msg, 7, 0),
vehicleId: jspb.Message.getFieldWithDefault(msg, 3, ""),
utime: jspb.Message.getFieldWithDefault(msg, 4, 0),
uclock: jspb.Message.getFieldWithDefault(msg, 6, 0),
hostname: jspb.Message.getFieldWithDefault(msg, 8, ""),
pilotId: jspb.Message.getFieldWithDefault(msg, 9, ""),
secondsSincePilotContact: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
shortname: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.ServerInfo}
 */
proto.flight_deck.ServerInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.ServerInfo;
  return proto.flight_deck.ServerInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.ServerInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.ServerInfo}
 */
proto.flight_deck.ServerInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setApiVersionMajor(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setApiVersionMinor(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVehicleId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUtime(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUclock(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHostname(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPilotId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSecondsSincePilotContact(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.ServerInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.ServerInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.ServerInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.ServerInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApiVersionMajor();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getApiVersionMinor();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getVehicleId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUtime();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getUclock();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getHostname();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPilotId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSecondsSincePilotContact();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getShortname();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional int32 api_version_major = 1;
 * @return {number}
 */
proto.flight_deck.ServerInfo.prototype.getApiVersionMajor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.ServerInfo} returns this
 */
proto.flight_deck.ServerInfo.prototype.setApiVersionMajor = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 api_version_minor = 7;
 * @return {number}
 */
proto.flight_deck.ServerInfo.prototype.getApiVersionMinor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.ServerInfo} returns this
 */
proto.flight_deck.ServerInfo.prototype.setApiVersionMinor = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string vehicle_id = 3;
 * @return {string}
 */
proto.flight_deck.ServerInfo.prototype.getVehicleId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.ServerInfo} returns this
 */
proto.flight_deck.ServerInfo.prototype.setVehicleId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 utime = 4;
 * @return {number}
 */
proto.flight_deck.ServerInfo.prototype.getUtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.ServerInfo} returns this
 */
proto.flight_deck.ServerInfo.prototype.setUtime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int64 uclock = 6;
 * @return {number}
 */
proto.flight_deck.ServerInfo.prototype.getUclock = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.ServerInfo} returns this
 */
proto.flight_deck.ServerInfo.prototype.setUclock = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string hostname = 8;
 * @return {string}
 */
proto.flight_deck.ServerInfo.prototype.getHostname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.ServerInfo} returns this
 */
proto.flight_deck.ServerInfo.prototype.setHostname = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string pilot_id = 9;
 * @return {string}
 */
proto.flight_deck.ServerInfo.prototype.getPilotId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.ServerInfo} returns this
 */
proto.flight_deck.ServerInfo.prototype.setPilotId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional float seconds_since_pilot_contact = 10;
 * @return {number}
 */
proto.flight_deck.ServerInfo.prototype.getSecondsSincePilotContact = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.flight_deck.ServerInfo} returns this
 */
proto.flight_deck.ServerInfo.prototype.setSecondsSincePilotContact = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional string shortname = 11;
 * @return {string}
 */
proto.flight_deck.ServerInfo.prototype.getShortname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.ServerInfo} returns this
 */
proto.flight_deck.ServerInfo.prototype.setShortname = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flight_deck.Error.prototype.toObject = function(opt_includeInstance) {
  return proto.flight_deck.Error.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flight_deck.Error} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.Error.toObject = function(includeInstance, msg) {
  var f, obj = {
type: jspb.Message.getFieldWithDefault(msg, 1, ""),
message: jspb.Message.getFieldWithDefault(msg, 2, ""),
stacktrace: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flight_deck.Error}
 */
proto.flight_deck.Error.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flight_deck.Error;
  return proto.flight_deck.Error.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flight_deck.Error} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flight_deck.Error}
 */
proto.flight_deck.Error.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStacktrace(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flight_deck.Error.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flight_deck.Error.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flight_deck.Error} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flight_deck.Error.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStacktrace();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.flight_deck.Error.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.Error} returns this
 */
proto.flight_deck.Error.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.flight_deck.Error.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.Error} returns this
 */
proto.flight_deck.Error.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string stacktrace = 3;
 * @return {string}
 */
proto.flight_deck.Error.prototype.getStacktrace = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.flight_deck.Error} returns this
 */
proto.flight_deck.Error.prototype.setStacktrace = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


goog.object.extend(exports, proto.flight_deck);
